import React from 'react';
import './AddButton.css';

interface AddButtonProps {
  onClick: () => void;
}

function AddButton({ onClick }: AddButtonProps) {
  return (
    <button className="add-button" onClick={onClick}>
      +
    </button>
  );
}

export default AddButton;