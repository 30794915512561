import React from 'react';
import { Sound } from '../types';
import './SoundButton.css';

interface SoundButtonProps {
  sound: Sound;
}

function SoundButton({ sound }: SoundButtonProps) {
  const playSound = () => {
    const audio = new Audio(sound.audioUrl);
    audio.play();
  };

  return (
    <button
      className="sound-button"
      style={{ backgroundColor: sound.color }}
      onClick={playSound}
    >
      {sound.name}
    </button>
  );
}

export default SoundButton;