import React, { useState } from 'react';
import Soundboard from './components/Soundboard';
import RecordModal from './components/RecordModal';
import { Sound } from './types';
import './App.css';

function App() {
  const [sounds, setSounds] = useState<Sound[]>([]);
  const [isRecording, setIsRecording] = useState(false);

  const addSound = (newSound: Sound) => {
    setSounds([...sounds, newSound]);
  };

  return (
    <div className="App">
      <Soundboard sounds={sounds} onAddClick={() => setIsRecording(true)} />
      {isRecording && (
        <RecordModal
          onClose={() => setIsRecording(false)}
          onSave={addSound}
        />
      )}
    </div>
  );
}

export default App;