import React from 'react';
import { Sound } from '../types';
import SoundButton from './SoundButton';
import AddButton from './AddButton';
import './Soundboard.css';

interface SoundboardProps {
  sounds: Sound[];
  onAddClick: () => void;
}

function Soundboard({ sounds, onAddClick }: SoundboardProps) {
  return (
    <div className="soundboard">
      {sounds.map((sound) => (
        <SoundButton key={sound.id} sound={sound} />
      ))}
      <AddButton onClick={onAddClick} />
    </div>
  );
}

export default Soundboard;