import React, { useState, useRef } from 'react';
import { Sound } from '../types';
import './RecordModal.css';

interface RecordModalProps {
  onClose: () => void;
  onSave: (sound: Sound) => void;
}

function RecordModal({ onClose, onSave }: RecordModalProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' }); // Updated MIME type
      mediaRecorderRef.current = mediaRecorder;

      const chunks: BlobPart[] = [];
      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/webm' }); // Updated MIME type
        setRecordedBlob(blob);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (err) {
      console.error('Error accessing microphone:', err);
      alert('Microphone access is required to record audio.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleSave = () => {
    if (recordedBlob) {
      const newSound: Sound = {
        id: Date.now().toString(),
        name: `Sound ${Date.now()}`,
        audioUrl: URL.createObjectURL(recordedBlob),
        color: getRandomColor(),
      };
      onSave(newSound);
      onClose();
    }
  };

  const getRandomColor = () => {
    return `#${Math.floor(Math.random()*16777215).toString(16)}`;
  };

  return (
    <div className="record-modal">
      <div className="record-modal-content">
        <h2>Record New Sound</h2>
        {!isRecording && !recordedBlob && (
          <button onClick={startRecording}>Start Recording</button>
        )}
        {isRecording && (
          <button onClick={stopRecording} style={{ backgroundColor: 'red', color: 'white' }}>Stop Recording</button>
        )}
        {recordedBlob && (
          <>
            <audio src={URL.createObjectURL(recordedBlob)} controls />
            <button onClick={handleSave}>Save</button>
          </>
        )}
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default RecordModal;